<template>

	<div class="wrap h-100">

		<v-row v-if="isThemeFetched && organizationId == 1" class="home">
			<v-col cols="12">
				<HomepageMenu :src="logoImage"></HomepageMenu>
				<section
					class="home__hero primary-background"
					:style="{ backgroundImage: `url(${backgroundImage})` }"
				>
					<v-container>
						<v-row>
							<v-col cols="6">
								<section class="home__hero--wrapper">
									<h1 class="text-dark">
										{{ mainHeader }}
									</h1>
									<p class="text-dark">
										{{ subHeader }}
									</p>
									<p class="text-dark">
										{{ mainText }}
									</p>
									<!-- <p class="mt-7"><a href="#" class="btn btn-primary shadow-none">Button</a></p> -->
								</section>
							</v-col>

						</v-row>
					</v-container>
				</section>

				<section class="bg-light position-relative" v-if="organizationId == 1">
					<div class="container py-0">
						<div class="row align-items-center">

							<div class="col-6 py-0">
								<h2 class="primary-text">Instant Insights</h2>
								<p class="text-muted">
									Receive a risk score reflecting the
									immediate risk to each team with your
									Exposure Control Plan(s). Learn what
									steps you can take to reduce infection
									risk and identify where attention and
									resources are needed.
								</p>
							</div>

							<div class="col-6 py-0">
							</div>
							<div class="rull-right-img"></div>
						</div>
					</div>
				</section>

				<section class="primary-background" v-if="organizationId == 1">
					<v-container>
						<v-row>
							<v-col cols="12" class="text-center mb-s mt">
								<h2>Courses available</h2></v-col
							></v-row
						>
						<v-row>
							<v-col cols="4">
								<v-row>
									<v-col cols="12">
										<section class="home__courses">
											<img src="../assets/1.jpg" />
										</section>
										<section class="home__description mb">
											<h3 class="h3 primary-text">
												SOP for lead surface wipe sampling
											</h3>
											<article
												class="home__description--single"
											>
												<p>
													This course is designed to help
													you understand the purpose of
													collecting surface lead dust
													samples and the standard
													step-by-step procedures for
													collecting properly documented
													lead dust wipe samples.
												</p>
											</article>
										</section>
									</v-col>
								</v-row>
							</v-col>

							<v-col cols="4">
								<v-row>
									<v-col cols="12">
										<section class="home__courses">
											<img src="../assets/2.jpg" />
										</section>
										<section class="home__description mb">
											<h3 class="h3 primary-text">
												SOP for lead air sampling
											</h3>
											<article
												class="home__description--single"
											>
												<p>
													This course is designed to help
													you understand the purpose of
													collecting airborne lead dust
													samples and the standard
													step-by-step procedures for
													collecting properly documented
													airborne lead dust samples.
												</p>
											</article>
										</section>
									</v-col>
								</v-row>
							</v-col>

							<v-col cols="4">
								<v-row>
									<v-col cols="12">
										<section class="home__courses">
											<img src="../assets/3.jpg" />
										</section>
										<section class="home__description mb">
											<h3 class="h3 primary-text">
												Industrial Hygiene
											</h3>
											<article
												class="home__description--single"
											>
												<p>
													This course is designed to
													introduce the principle of
													industrial hygiene which is the
													science of protecting and
													promoting the health and safety
													of workers in the workplace.
												</p>
											</article>
										</section>
									</v-col>
								</v-row>
							</v-col>
						</v-row>

		<!-- <div class="row">
		<v-carousel>
		<v-carousel-item
		src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"

		></v-carousel-item>

		<v-carousel-item
		src="https://cdn.vuetifyjs.com/images/cards/hotel.jpg"

		></v-carousel-item>

		<v-carousel-item
		src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"

		></v-carousel-item>
		</v-carousel>

		</div> -->

					</v-container>
				</section>

				<aside class="home__prefooter secondary" v-if="organizationId == 1">
					<v-container>
						<v-row class="centered">
							<v-col cols="8">
								<h3 class="home__prefooter--headtwo contrast-text">
									Ready to get started? <br>Register to see available courses.
								</h3>
							</v-col>
							<v-col cols="4"
								><v-btn elevation="2" large
									><router-link to="/registration"
										>Register</router-link
									></v-btn
								></v-col
							>
						</v-row>
					</v-container>
				</aside>
			</v-col>
		</v-row>

		<div class="student h-100" v-if="organizationId != 1">
			<header class="fixed-top">
				<div class="row pt-5 h-100 d-flex justify-content-center align-items-center">
					<div class="col-1"></div>
					<div class="col-5">
						<img class="student-logo" src="../assets/WHSAcademy.svg">
					</div>
					<div class="col-5 text-right">
						<!-- <router-link :to="'/student/login?organizationId=' + `${organizationId}`" class="btn btn-light btn-brand shadow-none">Login</router-link> -->
						<router-link to="/login" class="btn btn-light btn-brand shadow-none">Login</router-link>

					</div>
					<div class="col-1"></div>
				</div>
			</header>
			<div class="row h-100 d-flex justify-content-center align-items-center">
				<div class="col-1"></div>
				<div class="col-5">
					<h1>Welcome</h1>
					<p class="lead">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Beatae blanditiis non ipsum nobis eum modi dolorum molestias! Doloribus quis dolorum amet architecto consequuntur molestias, fugiat, enim inventore quo totam cupiditate.</p>
				</div>
				<div class="col-6 d-flex justify-content-center align-items-center bg-green h-100">
					<img src="../assets/WHSA_hero.svg">
				</div>
			</div>
			<footer class="student-footer py-4 pr-3 fixed-bottom w-100">
				<div class="row d-flex justify-content-center align-items-center">
					<div class="col-6">
						<img src="../assets/WHSAcademy_logo_footer.svg">
					</div>
					<div class="col-6 d-flex justify-content-end">
						<a href="" class="me-5 nav-item">Privacy Policy</a> <a href="" class="nav-item">Terms of Service</a>
					</div>
				</div>
			</footer>
		</div>

	</div>

</template>

<script>
import HomepageMenu from "./components/HomepageMenu.vue";
import { mapGetters } from "vuex";
import { get } from "@/util/requests/get";

export default {
	name: "HomeView",
	components: {
		HomepageMenu
	},
	data() {
		return {
			isStudent: false,
			reveal: false,
			baseOrg: null,
			swiperOption: {
				lidesPerView: 3
			}
		};
	},
	computed: {
		...mapGetters(["organization", "organizationExtras", "isThemeFetched"]),
		mainHeader() {
			if (this.organizationExtras?.main_header) {
				return this.organizationExtras?.main_header;
			} else {
				return 'Excepteur sint occaecat cupidatat non proident';
			}
		},
		subHeader() {
			if (this.organizationExtras?.sub_header) {
				return this.organizationExtras?.sub_header;
			} else {
				return "";
			}
		},
		mainText() {
			if (this.organizationExtras?.main_text) {
				return this.organizationExtras?.main_text;
			} else {
				return 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullam';
			}
		},
		backgroundImage() {
			if (
				this.$store.state.organization.homePageMainBackgroundImage?.file
			) {
				return this.$store.state.organization
					.homePageMainBackgroundImage?.file?.public_url;
			} else {
				return require('@/assets/home-hero.jpg')
				// return "https://f9z3k9s3.stackpathcdn.com/wp-content/uploads/2020/05/about-hero-scaled.jpg";
			}
		},

		organizationId() {
			if (this.$route.query.organizationId === undefined) {
				return 1;
			} else if (this.$route.query.organizationId !== undefined) {
				return this.$route.query.organizationId;
			} else if (this.organization?.id !== undefined) {
				return this.organization.id;
			} else if (localStorage.getItem("organizationId")) {
				return localStorage.getItem("organizationId");
			} else {
				return 1;
			}
		},
		logoImage() {
			if (this.$store.state.organization?.logo?.file) {
				return this.$store.state.organization?.logo?.file?.public_url;
			} else {
				return require("@/assets/main-logo.svg");
				// return this.baseOrg?.logo?.file?.public_url;
			}
		}
	},

	methods: {
		setSwiperRef: function(swiper) {
            this.swiperRef = swiper
        },
		async register() {
			try {
				const {
					data: { data }
				} = await get("/organization/1");
				this.baseOrg = data;
			} catch (error) {
				console.error(error);
			}
		}
	},
	async beforeMount() {
		localStorage.setItem("organizationId", this.organizationId);
		await this.$store.dispatch("getOrganizationInfo", this.organizationId);
		await this.$store.dispatch("setThemeColors");

		this.$store.dispatch("setIsThemeFetched", true);
	}
};
</script>

<style lang="scss" scoped>
@import url('https://cdn.jsdelivr.net/npm/swiper@9/swiper-bundle.min.css');
.bg-green {
	background-color: #CBDF98;
}

.student-footer {
	background-color: #fff;
	border-top: 1px solid #eee;

	.nav-item {
		font-size: 14px;
		color: #000000b5;
	}
}

.student-logo {
	margin-left: -20px;
}

.btn-brand {
    border-radius: 50px;
    color: #000;
    text-transform: uppercase;
    font-size: 16px;
    padding: 10px 30px;
}

.home__prefooter {

	h3 {
		line-height: 30px;
		font-weight: 700;
		font-size: 25px;
	}

	button {
		box-shadow: none !important;
		background-color: #fff !important;
		color: #2F91AE !important;
		text-transform: none;
		line-height: 30px;
		padding-top: 25px !important;
		padding-bottom: 25px !important;

		a {
			color: #2F91AE !important;
			font-weight: 800;
			font-size: 16px;
		}
	}
}

.rull-right-img {
	background: url('../assets/instant.jpg') no-repeat right;
    background-size: cover;
    height: 100%;
    width: 50%;
    position: absolute;
    right: 0;
    top: 0;
}

.position-relative .row {
	min-height: 650px;
}

.container-fluid.custom-fluid {
	// .col-2 {
	// 	flex: 0 0 11.9%;
    // 	max-width: 11.9%;
	// }

	// .col-4 {
	// 	flex: 0 0 38%;
    // 	max-width:38%;
	// }
}
.row {
	margin: 0;
}
.right-image {
	// background:url('http://localhost:8080/img/mid-2.f4732415.jpg') no-repeat right center / cover;
}

.home__hero--wrapper .btn {
	background-color: #2F91AE;
	color: #fff;
	border-color: #2F91AE;
	padding: 10px 50px;
}
</style>
